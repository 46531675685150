import React, { Component, useState, useEffect } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './SelectSingleFilterPlain.module.css';

import { filterGenerator, markets } from '../../../config/marketplace-custom-config';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      pageURL: ""
    };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });
    (queryParamName === "pub_category" && option === null) ? onSelect({["pub_subcategory"]: option}) : null;

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  selectSubOption(option, queryParamName, e) {
    const { onSelect } = this.props;
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  returnList(item, forcheck) {
    return item == forcheck;
  }

  componentDidMount() {
      this.setState({pageURL: window.location.search});
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBorder,
      useHighlight,
      setSizeOptions,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.labelSelected : css.label;

    const getURLParameter = (name) => {
      const urlParams = new URLSearchParams(this.state.pageURL);
      return urlParams.get(name);
    }

    const deleteURLParameter = (name) => {
      const urlParams = new URLSearchParams(this.state.pageURL);
      urlParams.delete(name);
      const newQueryString = urlParams.toString();
      history.go('/' + newQueryString);
    }

    const populateSizeOptions = (subcategory) => {      
      const market = window.location.pathname.split('/')[1];

      if (label === "ALL CATEGORIES") {
        try
        {
          setSizeOptions(markets.filter(item => this.returnList(item.key, market))[0]
          ?.categories.filter(item => this.returnList(item.key, getURLParameter("pub_category")))[0]
          ?.subcategories.filter(item => this.returnList(item.key, subcategory))[0].size);
        }
        catch(err)
        {
          setSizeOptions([]);
        }
      }
    }

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes} id={label.replace(/\s/g, '')}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{label}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>
        <ul className={optionsContainerClass}>
          {options?.map((option,i) => {
            // check if this option is selected            
            let sub_selected = getURLParameter("pub_subcategory")? true : null;
            const thisCategorySelected = (initialValue === option.key) || (getURLParameter("pub_size") === option);
            const selected = (thisCategorySelected && (sub_selected === null)) || (thisCategorySelected && label == "SIZE(S)");
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,            
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected || sub_selected,
                  [css.optionBorder]: !selected && !sub_selected,
                })
              : null;
            return (
              <div key={i + 436}>
              <li
                key={option.key || option}
                className={optionClass}
                onClick={(e) => {this.selectOption(option.key || option);
                              !thisCategorySelected? this.selectSubOption(null, "pub_subcategory", e) : null;}}
              >            
                { useBorder ? <span className={optionBorderClass} /> : null}
                {option.label || option}
              </li>
              <ul className={css.subcategories} key={i+1223}>
              {thisCategorySelected && option.subcategories && label === "ALL CATEGORIES"? 
                option.subcategories.map(subcategory => {
                  
                  // check if this option is selected
                  sub_selected = getURLParameter("pub_subcategory") === (subcategory.key || subcategory);
                  const optionClass = classNames(css.option, {
                    [css.optionHighlight]: sub_selected && useHighlight,
                  });
                  // menu item selected bullet or border class
                  const optionBorderClass = useBorder
                  ? classNames({
                    [css.optionBorderSelected]: sub_selected,
                    [css.optionBorder]: !sub_selected,
                  })
                  : null;
                  return (
                    <li
                      key={subcategory.key}
                      className={optionClass}
                      onClick={() => {this.selectSubOption(subcategory.key, "pub_subcategory")
                                      populateSizeOptions(subcategory.key)}}
                    >
                      { useBorder ? <span className={optionBorderClass} /> : null}
                    {subcategory.label}
                    </li>
                  )
                })
                : null}
            </ul>
            </div>
            );
          })}
          <button className={css.clearButton} onClick={e => this.selectOption(null, e)}>
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </ul>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  /* options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired, */
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlain;
