import React, { useState, useEffect } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { checkHomeUtil } from '../../../util/checkHome';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    resultsFoundText,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  let searchTerm = null;
  let homecheck = null;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const checkHome = () => {
    homecheck = checkHomeUtil(window?.location?.search)
  }

  mounted? checkHome(): null;

  const changeSearchText = () => {
    const getSearchUrl = window?.location.href.includes('/s')? window.location.href : null;
    const url = getSearchUrl? new URL(getSearchUrl) : null;
    searchTerm = url?.searchParams.get("keywords");
  };

  mounted? changeSearchText() : null;

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              homecheck? (<FormattedMessage id="MainPanelHeader.FeaturedTerm"/>) : (
              searchTerm? <FormattedMessage id="MainPanelHeader.foundResultsforTerm" values={{ searchTerm: '"' + searchTerm.toUpperCase() +'"'}}/> :
                <FormattedMessage id="MainPanelHeader.foundResults" values={{ count: resultsCount }}/>
            ))}
          </span>
        </div>
        <div className={css.sortyByWrapper}>
          {/* <span className={css.sortyBy}>
            <FormattedMessage id="MainPanelHeader.sortBy" />
          </span> */}
          {sortByComponent}
        </div>
      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
  resultsFoundText: string,
};

export default MainPanelHeader;
